/****************************************************
* Main Backup Component
****************************************************/

.backup-container {
    height: calc(100vh - 70px);
    max-width: 1500px;
    margin: auto;
    display: flex;
}

.backup-sidebar {
    width: 250px;
}
.b-sidebar-inner-wrap {
    height: 100%;
    background: #fff;
    overflow: auto;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
}
.b-sidebar-list-item {
    padding: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #000;
}
.b-sidebar-list-item:hover,
.b-sidebar-list-item.active {
    background: #f5f5f5;
    color: orangered;
}
.b-sidebar-list-item-icon {
    display: inherit;
}
.b-sidebar-list-item-text {
    font-size: 15px;
}

.backup-content {
    flex-grow: 1;
}

/****************************************************
* List Component
****************************************************/

.backup-list {
    position: relative;
    overflow: hidden;
}
.backup-list-header {
    width: 95%;
    max-width: 730px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.log-date-filter {
    border: none;
    background: #fff;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
    padding: 7px 15px;
    width: 175px;
    font-size: 14px;
}
.list-container {
    height: calc(100vh - 155px);
    overflow: auto;
}
.list-middel-container {
    max-width: 730px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    position: relative;
}
.list-inner-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 2px;
    padding-bottom: 10px;
}
.list-items {
    width: 100%;
    padding: 10px 15px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    position: relative;
}
.sticky {
    position: sticky;
    z-index: 1;
    top: 0;
}
.field-name {
    font-weight: 500;
}
.field-name:last-child {
    width: 100%;
    max-width: 120px;
    text-align: center;
    border-right: none;
}

.field-value-wrap {
    display: flex;
    gap: 12px;
    width: calc(60% + 12px);
    flex-grow: 1;
}
.field-value-wrap .f-v {
    width: 50%;
    border-right: 1px solid #9f9f9f40;
    padding: 5px 0;
    padding-right: 10px;
    overflow: auto;
}
.field-value-wrap .f-v::-webkit-scrollbar-thumb,
.field-value-wrap .f-v::-webkit-scrollbar {
    height: 5px;
    background: none;
}
.field-value-wrap .f-v:hover::-webkit-scrollbar {
    background: #f5f5f5;
}
.field-value-wrap .f-v:hover::-webkit-scrollbar-thumb {
    background: #2b2b2b40;
}
.field-checkbox {
    border-right: 1px solid #9f9f9f40;
    min-width: 50px;
}
.field-checkbox span {
    padding: 5px;
}
.action-wrap {
    width: 30%;
    max-width: 120px;
    display: flex;
    justify-content: center;
    gap: 5px;
}
.action-btn {
    min-width: 30px !important;
    height: 37px;
    border-radius: 20px !important;
    color: inherit !important;
}
.action-btn:hover {
    background: #eee;
}
.action-wrap-close {
    display: none !important;
}

.select-modal {
    position: absolute;
    top: 10px;
    right: 0;
    background: #fff;
    border-radius: 5px 0 0 5px;
    box-shadow: 1px 5px 5px rgb(0 0 0 / 10%);
    z-index: 9;
    padding: 15px;
    animation: rightToLeft 0.3s ease-in-out;
}
@keyframes rightToLeft {
    from {
        right: -100%;
    }
    to {
        right: 0;
    }
}
.status-wrap {
    border-top: 1px solid lightgray;
}

.ani-top {
    animation: ani-top 0.3s ease-in-out;
}
@keyframes ani-top {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.ani-bottom {
    animation: ani-bottom 0.3s ease-in-out;
}
@keyframes ani-bottom {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.ani-right {
    animation: ani-right 0.3s ease-in-out;
}
@keyframes ani-right {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.action-wrap-m {
    display: none;
}

.select-modal-close {
    position: absolute !important;
    top: 0;
    left: -60px;
    background-color: #fff !important;
    color: #000 !important;
    z-index: 9;
    padding: 13px 10px !important;
    border-radius: 50% !important;
    min-width: 50px !important;
}
.select-modal-open {
    background-color: #fff !important;
    color: #000 !important;
    min-width: 50px !important;
    height: 40px;
}

@media screen and (max-width: 991px) {
    .backup-sidebar {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .field-value-wrap {
        flex-direction: column;
        gap: 0;
    }
    .field-value-wrap .f-v {
        width: 100%;
        padding: 0 10px 0 0;
    }
}

@media screen and (max-width: 600px) {
    .field-checkbox {
        min-width: 40px;
    }
    .field-value-wrap .f-v {
        width: calc(100vw - 140px);
    }
    .action-btn {
        min-width: 40px !important;
        height: 40px;
    }
    .action-wrap-close {
        display: inline-flex !important;
    }
    .list-items {
        gap: 8px;
        padding: 10px 7px;
    }
    .action-title {
        width: 60px !important;
        padding-right: 0;
        flex-grow: unset;
    }
    .action-wrap-m {
        display: flex;
        width: 40px;
    }
    .action-wrap-d {
        position: absolute;
        right: 65px;
        flex-wrap: wrap;
        z-index: 9;
        width: 90px;
        animation: ani-btn-left 0.3s ease-in-out;
    }
    .btn-mobile-hide {
        display: none;
    }
    .action-wrap-d .action-btn {
        background: #fff !important;
        box-shadow: 1px 3px 10px rgb(0 0 0 / 20%);
        padding: 0;
    }
    .action-wrap-d .action-btn:nth-child(1) {
        animation: ani-btn-left 0.3s ease-in-out;
    }
    .action-wrap-d .action-btn:nth-child(2) {
        animation: ani-btn-left 0.3s ease-in-out;
    }
    .action-wrap-d .action-btn:nth-child(3) {
        animation: ani-btn-left 0.3s ease-in-out;
    }
    .action-wrap-d .action-btn:nth-child(4) {
        animation: ani-btn-left 0.3s ease-in-out;
    }
}

@keyframes ani-btn-left {
    0% {
        transform: translateX(50px) scale(0);
    }
    100% {
        transform: translateX(0) scale(1);
    }
}

/****************************************************
* Logs Component
****************************************************/

.log-text-title {
    font-size: 14px;
    font-weight: 500;
    flex-grow: 1;
}
.log-data-time-title {
    font-size: 14px;
    font-weight: 500;
    width: 150px;
    text-align: right;
}
.log-text {
    flex-grow: 1;
}
.log-data-time {
    width: 150px;
    text-align: right;
    font-size: 12px;
}
.list-items-log {
    padding: 5px 15px;
}

@media screen and (max-width: 768px) {
    .list-items-log {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }
    .log-data-time {
        width: 100%;
        text-align: right;
    }
}

/****************************************************
* Add Domain Component
****************************************************/

.domain-form {
    max-width: 500px;
    margin-top: 30px;
}
