.login, .verify, .forgot-pass {
    height: calc(100vh - 70px);
    overflow: auto;
}

.form-content {
    display: flex;
    padding: 30px 0;
}

figure {
    margin: 0;
    margin-bottom: 50px;
    text-align: center;
}
figure img {
    max-width: 220px;
    height: auto;
}

.form-container {
    width: 900px;
    background: #fff;
    margin: 50px auto;
    box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -o-border-radius: 20px;
    -ms-border-radius: 20px;
}

.signup-form,
.signup-image,
.signin-form,
.signin-image {
    width: 50%;
    overflow: hidden;
}
.signup-image {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.form-title {
    line-height: 1.66;
    margin: 0;
    padding: 0;
    font-weight: 700;
    color: #222;
    font-size: 30px;
    margin-bottom: 33px;
}

.signup-image-link {
    font-size: 15px;
    font-weight: 500;
    color: #222;
    display: block;
    text-align: center;
}
.signup-image-link:hover {
    text-decoration: underline;
}

.signup-form {
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 34px;
}
.l-form-group {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
}

.text-field {
    width: 100%;
    display: block;
    border: none;
    border-bottom: 1px solid #999;
    padding: 6px 30px 6px 0;
    box-sizing: border-box;
}
.text-field::-webkit-input-placeholder {
    color: #999;
}
.text-field::-moz-placeholder {
    color: #999;
}
.text-field:-ms-input-placeholder {
    color: #999;
}
.text-field:-moz-placeholder {
    color: #999;
}
.text-field:focus {
    border-bottom: 1px solid #222;
}
.text-field:focus::-webkit-input-placeholder {
    color: #222;
}
.text-field:focus::-moz-placeholder {
    color: #222;
}
.text-field:focus:-ms-input-placeholder {
    color: #222;
}
.text-field:focus:-moz-placeholder {
    color: #222;
}
.pass-handle {
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
}
.agree-term {
    display: inline-block;
    width: auto;
}

.social-login {
    text-align: center;
    margin-top: 35px;
}
.social-label {
    display: inline-block;
    font-weight: 500;
}
.social-btn {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 12px;
    width: 280px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px -10px rgba(0,0,0,0.90);
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    border: none;
}
.social-btn .s_title {
    margin-left: 12px;
}
.socials-google {
    background-color: #FFF;
    color: rgb(93, 93, 93);
}
.socials-facebook {
    background-color: #1778f2;
    color: #FFF;
}
.socials-facebook:hover {
    color: #FFF;
}
.signin-form {
    margin-right: 30px;
    margin-left: 30px;
}
.signin-image {
    margin-left: 40px;
    margin-right: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.forgot-link{
    color: #1976d2;
}

.error {
    color: red;
    font-size: 13px;
}

.kep-login-facebook.metro {
    height: 40px;
    font-size: 14px;
    padding-left: 49px;
    width: 280px;
    display: flex;
    align-items: center;
    border-radius: 10px !important;
    font-weight: 500;
    font-family: inherit;
    text-transform: capitalize;
    background: #1778f2;
    position: relative;
    border: none;
    box-shadow: 0px 0px 20px -10px rgba(0,0,0,0.90);
}
.kep-login-facebook.metro::before {
    content: '';
    background: url('../../images/fb-logo.png');
    background-size: cover;
    position: absolute;
    height: 25px;
    width: 25px;
    left: 12px;
}

@media screen and (max-width: 1000px) {
    .form-container {
        width: calc(100% - 30px);
        max-width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .form-content {
        flex-direction: column;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -o-flex-direction: column;
        -ms-flex-direction: column;
        justify-content: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        -o-justify-content: center;
        -ms-justify-content: center;
    }
    .signup-form {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding: 0 15px;
        max-width: 500px;
        margin: auto;
        width: 100%;
    }
    .signin-image {
        margin-left: 0;
        margin-right: 0;
        margin-top: 50px;
        order: 2;
        -moz-order: 2;
        -webkit-order: 2;
        -o-order: 2;
        -ms-order: 2;
    }
    .signup-image,
    .signin-image {
        width: auto;
    }
    .social-login {
        justify-content: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        -o-justify-content: center;
        -ms-justify-content: center;
    }
    .form-button {
        text-align: center;
    }
    .signin-form {
        order: 1;
        -moz-order: 1;
        -webkit-order: 1;
        -o-order: 1;
        -ms-order: 1;
        margin-right: 0;
        margin-left: 0;
        padding: 0 15px;
        max-width: 500px;
        width: 100%;
        margin: auto;
    }
    .form-title {
        text-align: center;
    }
    .form-container {
        margin: 20px auto;
    }
}
@media screen and (max-width: 400px) {
    .social-login {
        flex-direction: column;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -o-flex-direction: column;
        -ms-flex-direction: column;
    }
    .social-label {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
