.sidebar {
    height: calc(100vh - 70px);
    width: 100%;
    position: absolute;
    display: none;
    z-index: 2;
    background: rgba(0, 0, 0, 0.1)
}
.sidebar.open {
    display: block;
}
.sidebar-inner-wrap {
    height: 100%;
    width: 250px;
    background: #FFF;
    overflow: auto;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
}
.sidebar-list-item {
    padding: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #000;
}
.sidebar-list-item:hover, .sidebar-list-item.active {
    background: #F5F5F5;
    color: orangered;
}
.sidebar-list-item-icon {
    display: inherit;
}
.sidebar-list-item-text {
    font-size: 15px;
}