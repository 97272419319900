.Navbar {
    width: 100%;
    background: #282c34;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
}
.Navbar-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 50px;
}
.Navbar-menu-btn {
    display: none !important;
    position: absolute !important;
    left: 15px;
    min-width: 30px !important;
    height: 40px;
    border-radius: 20px !important;
    color: #FFF !important;
}
.Navbar a {
    font-size: 17px;
    color: #FFF;
    text-decoration: none;
}
.Navbar-logo {
    height: 60px;
}
.Navbar-right-btns {
    margin-left: auto;
}
@media screen and (max-width: 768px) {
    .Navbar {
        height: auto;
    }
    .Navbar-wrap {
        flex-direction: column;
        justify-content: center;
        padding: 5px;
    }
    .Navbar-right-btns {
        margin: 0;
    }
    .Navbar-right-btns {
        display: none;
    }
    .Navbar-menu-btn {
        display: block!important;
    }
}
@media screen and (max-width: 500px) {
    .Navbar {
        padding: 0 20px;
    }
}